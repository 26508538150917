import axios from 'axios';
import contractListData from "../data/contract.list.json";
import { getDateWithFormat } from "./Utils";
import Dexie from 'dexie';
import ipfsClient from 'ipfs-http-client';

import {Globals} from '../constants/globals'

const TABLE_CONTRACTS = 'contracts';

const db = new Dexie('hypercontract');
db.version(1).stores({
    [TABLE_CONTRACTS] : '++id' 
});

const ipfsOptions = {
    "host": "prince.trqk.io",
    "protocol": "https",
    "port": 5001
}

export default class DataLib {

    static async getContracts() {
        let contracts = null;
		try {
            contracts = await db[TABLE_CONTRACTS].toArray();
		} catch(e) {
            console.log('Error', e);
        }

        if (!contracts || (typeof contracts === 'undefined')) {
			contracts = contractListData.data;
        }

        return contracts;
    }

    static async getContract(id) {
        let contracts = await DataLib.getContracts();
        let contract = contracts.filter((item) => item.id === Number(id));

        if (contract && contract.length > 0) {
            contract = contract[0];
        }
        return contract;
    }

    static async getManifest(section) {
        let response = await axios.get(`/api/manifest`);
        if (response.data) {
            let categories = response.data.filter(x => x.name === 'categories')[0].children;
            let manifest = {
                all: {},
                required: categories.filter(x => x.name === 'required')[0].children,
                clauses:  categories.filter(x => x.name === 'clauses')[0].children
            }

            let all = [...manifest.required,...manifest.clauses];
            manifest.all = all.sort((a, b) => a.order < b.order ? -1 : 1);
            
            return manifest;
        } else {
            return null;
        }
    }

    static async addContract(contract) {
        
        contract.createDate = getDateWithFormat(); 
        contract.clauses = [];
        let requiredClauses = (await DataLib.getManifest('')).required;
        for(let r=0; r<requiredClauses.length; r++) {

            let clause = requiredClauses[r];
            if (clause.interface.hidden) { 
                continue; 
            }
            if (!clause.interface.enabled) { 
                continue; 
            }

            let parameters = clause.parameters ?? [];
            for(let p=0; p<parameters.length; p++) {
                parameters[p].value = '';
            }

            contract.clauses.push({
                id: 'required-' + clause.name,
                order: clause.order,
                required: true,
                name: clause.name,
                title: clause.title,
                markup: clause.preview.open + clause.markup.join(' ') + clause.preview.close,
                parameters: parameters
            });

        };

        let compositions = [];
        contract.tracks.forEach((track) => {
            compositions.push({
                title: track.name,
                detail: track.hash ? track.hash : track.name
            });
        });
        contract.parameters = {
            "x$compositions": compositions
        }

        return await this.saveContract(contract);
    }


    static async saveContract(contract) {
        contract.clauses = contract.clauses.sort((a,b) => a.order < b.order ? -1 : 1)
        await db.contracts.put(contract);
        return contract;
    }


    static async reviewContract(contract) {
        contract[Globals.COL_STATUS] = Globals.STAGE_REVIEW;
        
        await db.contracts.put(contract);
        return contract;
    }
 

    static async signContract(contract) {
        contract[Globals.COL_STATUS] = Globals.STAGE_PENDING;
        
        await db.contracts.put(contract);
        return contract;
    }   
    
    static async previewContract(contract) {
        let pdf = null;
        await axios.post('/api/preview', contract.data)
            .then((response) => {
            pdf = response.data.pdf;
            if (pdf == null) {
                throw(new Error('PDF generation failed'));
            }
            }, (error) => {
            console.log('Error', error);
            });
        let buffer = Uint8Array.from(pdf.data);
        let blob = new Blob([buffer], {type: "application/pdf"});
        return blob;
    }

    static async ipfsUpload(fileUploads, wrap, onlyHash) {
        let ipfs = ipfsClient(ipfsOptions);
        const options = {
            wrapWithDirectory: wrap,
            onlyHash: onlyHash,
            pin: true,
            timeout: '10m'
        }

        let ipfsResult = {
            files: [],
            folder: ''
        }

        const addSource = ipfs.add(fileUploads, options);
        for await (const fileInfo of addSource) {
            if (ipfsResult.files.length < fileUploads.length) {
                ipfsResult.files.push(fileInfo.cid.string);
            } else {
                ipfsResult.folder = fileInfo.cid.string;
            }
        }
        console.log('IPFS hashes', ipfsResult);
        return ipfsResult;
    }

    static async publishContract(contract) {

        let progress = (m, type, timeout) => {
            if (contract.progress) {
                contract.progress(m, type ?? 'info', timeout ?? 10000);
            }
        }

        let fileUploads = [];
        let compositions = '';
        contract.tracks.forEach(async (track) => { 
            fileUploads.push({
                path: track.name,
                content: track.data
            });
        });

        // First get file hashes for tracks
        progress('Computing cryptographic hashes for tracks...');
        let ipfsPreview = await DataLib.ipfsUpload(fileUploads, false, true);
        for(let f=0; f<contract.tracks.length; f++) {
            contract.tracks[f].hash = ipfsPreview.files[f];
            compositions += `<li>${contract.tracks[f].name}<br /><i>(${contract.tracks[f].hash})</i></li>`
        }

        // Update compositions
        progress('Updating contract with track cryptographic hashes...');
        contract.data.sections['/categories/required/preamble/x$compositions'] = '<ol>' + compositions + '</ol>';

        // Get PDF
        progress('Generating contract PDF with user-provided values...');
        let pdfBlob = await DataLib.previewContract(contract);
        fileUploads.push({
            path: 'Hypercontract.pdf',
            content: pdfBlob
        });

        progress('Uploading tracks and PDF to IPFS decentralized storage network...');
        let ipfsResult = await DataLib.ipfsUpload(fileUploads, true, false);
        contract.pdfUrl = `${ipfsOptions.protocol}://${ipfsOptions.host}/ipfs/${ipfsResult.files[ipfsResult.files.length-1]}`;
        contract.filesUrl =  `${ipfsOptions.protocol}://${ipfsOptions.host}/ipfs/${ipfsResult.folder}`;

        progress('Updating contract with PDF hash...');
        contract.data.hashes = ipfsResult;

        progress('Compiling and deploying encrypted Smart Contract code to public Ethereum blockchain...');

        await axios.post('/api/publish', contract.data)
            .then((response) => {
                let data = response.data;
                if (data.error) {
                    throw(data.error);
                }
                if ((data.deployedAddress !== null) && (data.deployedAddress !== 'undefined') && (data.deployedAddress !== '')) {
                    contract.blockchainUrl = `https://ropsten.etherscan.io/address/${data.deployedAddress}`;          
                }
            }, (error) => {
                console.log('Error', error);
                contract.blockchainUrl = '';          
            });

        if (contract.blockchainUrl !== '') {
            contract[Globals.COL_STATUS] = Globals.STAGE_ACTIVE;
            delete contract.progress;
            await db.contracts.put(contract);    
        } else {
            progress('Blockchain deployment failed. Refresh page to re-try.', 'error', 120000);
            delete contract.progress;
        }
        return contract;

    }

    static async deleteContracts(contracts) {
        contracts.map(async (id) => {
            await db.contracts.delete(id);
        })
        return await DataLib.getContracts();
    }

    static readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

}