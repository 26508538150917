
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
	CONTRACT_GET_DETAILS,
	CONTRACT_ADD_CLAUSE,
	CONTRACT_DELETE_CLAUSE,
	CONTRACT_PREVIEW,
	CONTRACT_SAVE,
	CONTRACT_REVIEW,
	CONTRACT_SIGN,
	CONTRACT_PUBLISH
} from '../actions';

import {
	getContractDetailSuccess,
	getContractDetailError,
	getContractSaveSuccess,
	getContractSaveError,
	getContractPreviewSuccess,
	getContractPreviewError,
	getContractReviewSuccess,
	getContractReviewError,
	getContractSignSuccess,
	getContractSignError,
	getContractPublishSuccess,
	getContractPublishError,
	saveContract
} from './actions';

import DataLib from '../../helpers/DataLib';


const getContractDetailRequest = async (id) => {
	let contractPromise =  new Promise(async (success, fail) => {
		let contract = await DataLib.getContract(id);
		if (!contract.hasOwnProperty('clauses')) {
			contract.clauses = [];
		}
		success(contract);
	});

	let manifestPromise = new Promise(async (success, fail) => {
		let manifest = await DataLib.getManifest('');
		success(manifest);
	})

	return await Promise.all([contractPromise, manifestPromise])
						.then(values => {
							return {
								contract: values[0],
								manifest: values[1]
							}
						})
						.catch(error => error)
}

function* getContractDetail( { payload } ) {
	try {
		const response = yield call(getContractDetailRequest, payload);
		yield put(getContractDetailSuccess(response));
	} catch (error) {
		yield put(getContractDetailError(error));
	}
}

const addClauseRequest = async (clauseId, contract) => {

	
	let manifest = await DataLib.getManifest('');
	let item = (manifest.clauses.filter((o) => o.name === clauseId))[0];
	contract.clauses.push({ 
								id: 'clause-' + item.name,
								name: item.name, 
								required: false, 
								title: item.title, 
								order: item.order, 
								parameters: item.parameters, 
								markup: item.preview.open + item.markup.join(' ') + item.preview.close 
						});

	return await new Promise((success, fail) => {
		success(contract);
	})
	.then(response => response)
	.catch(error => error);

}

const deleteClauseRequest = async (clauseId, contract) => {

		contract.clauses = contract.clauses.filter(x => x.id !== clauseId);
		return await new Promise((success, fail) => {
			success(contract);
		})
		.then(response => response)
		.catch(error => error);
	
}

function* addClause({ payload }) {
	try {
		const { clauseId, contract } = payload;
		const response = yield call(addClauseRequest, clauseId, contract);
		yield put(saveContract(response));
	} catch (error) {
		yield put(getContractDetailError(error));
	}
}

function* deleteClause({ payload }) {
	try {
		const { clauseId, contract } = payload;
		const response = yield call(deleteClauseRequest, clauseId, contract);
		yield put(saveContract(response));
	} catch (error) {
		yield put(getContractDetailError(error));
	}
}

const saveContractRequest = async (contract) => {
	
	return await new Promise(async (success, fail) => {
							DataLib.saveContract(contract);
							success(contract);
						})
						.then(response => response)
						.catch(error => error);
}

function* save({ payload }) {
	try {
		const response = yield call(saveContractRequest, payload);
		yield put(getContractSaveSuccess(response));
	} catch (error) {
		yield put(getContractSaveError(error));
	}

}

const downloadPdf = (blob) => {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'HyperContract.pdf';
    link.click();
};

const previewContractRequest = async (contract) => {
	
	return await new Promise(async (success, fail) => {
							let blob = await DataLib.previewContract(contract);
							downloadPdf(blob);
							success(blob);
						})
						.then(response => response)
						.catch(error => error);
}

function* preview({ payload }) {
	try {
		const response = yield call(previewContractRequest, payload);
		yield put(getContractPreviewSuccess(response));
	} catch (error) {
		yield put(getContractPreviewError(error));
	}

}

const reviewContractRequest = async (contract) => {
	
	return await new Promise(async (success, fail) => {
							await DataLib.reviewContract(contract);
							success(contract);
						})
						.then(response => response)
						.catch(error => error);
}

function* review({ payload }) {
	try {
		const response = yield call(reviewContractRequest, payload);
		yield put(getContractReviewSuccess(response));
	} catch (error) {
		yield put(getContractReviewError(error));
	}

}

const signContractRequest = async (contract) => {
	
	return await new Promise(async (success, fail) => {
							await DataLib.signContract(contract);
							success(contract);
						})
						.then(response => response)
						.catch(error => error);
}

function* sign({ payload }) {
	try {
		const response = yield call(signContractRequest, payload);
		yield put(getContractSignSuccess(response));
	} catch (error) {
		yield put(getContractSignError(error));
	}

}


const publishContractRequest = async (contract) => {
	
	return await new Promise(async (success, fail) => {
							await DataLib.publishContract(contract);
							success(contract);
						})
						.then(response => response)
						.catch(error => error);
}


function* publish({ payload }) {
	try {
		const response = yield call(publishContractRequest, payload);
		yield put(getContractPublishSuccess(response));
	} catch (error) {
		yield put(getContractPublishError(error));
	}
}

export function* watchGetContractDetail() {
	yield takeEvery(CONTRACT_GET_DETAILS, getContractDetail);
}

export function* watchAddClause() {
	yield takeEvery(CONTRACT_ADD_CLAUSE, addClause);
}

export function* watchDeleteClause() {
	yield takeEvery(CONTRACT_DELETE_CLAUSE, deleteClause);
}

export function* watchPublishContract() {
	yield takeEvery(CONTRACT_PUBLISH, publish);
}

export function* watchPreviewContract() {
	yield takeEvery(CONTRACT_PREVIEW, preview);
}

export function* watchSaveContract() {
	yield takeEvery(CONTRACT_SAVE, save);
}

export function* watchReviewContract() {
	yield takeEvery(CONTRACT_REVIEW, review);
}

export function* watchSignContract() {
	yield takeEvery(CONTRACT_SIGN, sign);
}

export default function* rootSaga() {
	yield all([
		fork(watchGetContractDetail),
		fork(watchDeleteClause),
		fork(watchAddClause),
		fork(watchPublishContract),
		fork(watchPreviewContract),
		fork(watchSaveContract),
		fork(watchReviewContract),
		fork(watchSignContract)
	]);
}