import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  CONTRACT_LIST_GET_LIST,
  CONTRACT_LIST_ADD,
  CONTRACT_LIST_DELETE
} from "../actions";

import {
  getContractListSuccess,
  getContractListError,
  addContractSuccess,
  addContractError,
  deleteContractsSuccess,
  deleteContractsError
} from "./actions";

import DataLib from '../../helpers/DataLib';

const getContractListRequest = async () => {
  return await new Promise(async (success, fail) => {
      success(await DataLib.getContracts());
  })
    .then(response => response)
    .catch(error => error);
};

function* getContractList() {
  try {
    const response = yield call(getContractListRequest);
    yield put(getContractListSuccess(response));
  } catch (error) {
    yield put(getContractListError(error));
  }
}

const addContractRequest = async item => {
  return await new Promise(async (success, fail) => {
    let result = await DataLib.addContract(item);
    success(result);
  })
    .then(response => response)
    .catch(error => error);
}

const deleteContractsRequest = async items => {
  return await new Promise(async (success, fail) => {
    let result = await DataLib.deleteContracts(items);
    success(result);
  })
    .then(response => response)
    .catch(error => error);
}

function* addContract({ payload }) {
  try {
    const response = yield call(addContractRequest, payload);
    yield put(addContractSuccess(response));
  } catch (error) {
    yield put(addContractError(error));
  }
}

function* deleteContracts({ payload }) {
  try {
    const response = yield call(deleteContractsRequest, payload);
    yield put(deleteContractsSuccess(response));
  } catch (error) {
    yield put(deleteContractsError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CONTRACT_LIST_GET_LIST, getContractList);
}

export function* watchAddContract() {
  yield takeEvery(CONTRACT_LIST_ADD, addContract);
}

export function* watchDeleteContracts() {
  yield takeEvery(CONTRACT_LIST_DELETE, deleteContracts);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(watchAddContract), fork(watchDeleteContracts)]);
}
