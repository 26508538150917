
import {defaultLocale,localeOptions} from '../../constants/defaultValues'
import Users from "../../data/users.json";

import {
	CHANGE_LOCALE,
	CHANGE_USER,
	GET_USER
} from '../actions';

const INIT_STATE = {
	locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x=>x.id===localStorage.getItem('currentLanguage')).length>0) ? localStorage.getItem('currentLanguage') : defaultLocale,
	user: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : Users[0]
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LOCALE:
		return { ...state, locale:action.payload};

		case CHANGE_USER:
		return { ...state, user:action.payload};

		case GET_USER:
		return { ...state, user: INIT_STATE.user};

		default: return { ...state };
	}
}