import {
    CONTRACT_GET_DETAILS,
    CONTRACT_GET_DETAILS_SUCCESS,
    CONTRACT_GET_DETAILS_ERROR,
    CONTRACT_SAVE_SUCCESS,
    CONTRACT_SAVE_ERROR,
    CONTRACT_PREVIEW_SUCCESS,
    CONTRACT_PREVIEW_ERROR,
    CONTRACT_REVIEW_SUCCESS,
    CONTRACT_REVIEW_ERROR,
    CONTRACT_SIGN_SUCCESS,
    CONTRACT_SIGN_ERROR,
    CONTRACT_PUBLISH_SUCCESS,
    CONTRACT_PUBLISH_ERROR,
    CONTRACT_ADD_CLAUSE,
    CONTRACT_DELETE_CLAUSE,
    CONTRACT_PREVIEW,
    CONTRACT_SAVE,
    CONTRACT_REVIEW,
    CONTRACT_SIGN,
    CONTRACT_PUBLISH
} from '../actions';


export const getContractDetail = (id) => ({
    type: CONTRACT_GET_DETAILS,
    payload: id
});

export const getContractDetailSuccess = (contract) => ({
    type: CONTRACT_GET_DETAILS_SUCCESS,
    payload: contract
});

export const getContractDetailError = (error) => ({
    type: CONTRACT_GET_DETAILS_ERROR,
    payload: error
});

export const getContractPreviewSuccess = (contract) => ({
    type: CONTRACT_PREVIEW_SUCCESS,
    payload: { contract }
});

export const getContractPreviewError = (error) => ({
    type: CONTRACT_PREVIEW_ERROR,
    payload: error
});

export const getContractSaveSuccess = (contract, manifest) => ({
    type: CONTRACT_SAVE_SUCCESS,
    payload: { contract, manifest }
});

export const getContractSaveError = (error) => ({
    type: CONTRACT_SAVE_ERROR,
    payload: error
});

export const getContractReviewSuccess = (contract) => ({
    type: CONTRACT_REVIEW_SUCCESS,
    payload: { contract }
});

export const getContractReviewError = (error) => ({
    type: CONTRACT_REVIEW_ERROR,
    payload: error
});

export const getContractSignSuccess = (contract) => ({
    type: CONTRACT_SIGN_SUCCESS,
    payload: { contract }
});

export const getContractSignError = (error) => ({
    type: CONTRACT_SIGN_ERROR,
    payload: error
});

export const getContractPublishSuccess = (contract) => ({
    type: CONTRACT_PUBLISH_SUCCESS,
    payload: contract
});

export const getContractPublishError = (error) => ({
    type: CONTRACT_PUBLISH_ERROR,
    payload: error
});

export const addContractClause = (clauseId, contract) => ({
    type: CONTRACT_ADD_CLAUSE,
    payload: {clauseId, contract}
});

export const deleteContractClause = (clauseId, contract) => ({
    type: CONTRACT_DELETE_CLAUSE,
    payload: {clauseId, contract}
});

export const previewContract = (contract) => ({
    type: CONTRACT_PREVIEW,
    payload: contract
});

export const saveContract = (contract) => ({
    type: CONTRACT_SAVE,
    payload: contract
});

export const reviewContract = (contract) => ({
    type: CONTRACT_REVIEW,
    payload: contract
});

export const signContract = (contract) => ({
    type: CONTRACT_SIGN,
    payload: contract
});

export const publishContract = (contract) => ({
    type: CONTRACT_PUBLISH,
    payload: contract
});