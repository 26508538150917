import {
    CONTRACT_LIST_GET_LIST,
    CONTRACT_LIST_GET_LIST_SUCCESS,
    CONTRACT_LIST_GET_LIST_ERROR,
    CONTRACT_LIST_GET_LIST_WITH_FILTER,
    CONTRACT_LIST_GET_LIST_WITH_ORDER,
    CONTRACT_LIST_GET_LIST_SEARCH,
    CONTRACT_LIST_ADD,
    CONTRACT_LIST_ADD_SUCCESS,
    CONTRACT_LIST_ADD_ERROR,
    CONTRACT_LIST_DELETE,
    CONTRACT_LIST_DELETE_SUCCESS,
    CONTRACT_LIST_DELETE_ERROR,
    CONTRACT_LIST_SELECTED_CHANGE
} from '../actions';


export const getContractList = () => ({
    type: CONTRACT_LIST_GET_LIST
});

export const getContractListSuccess = (items) => ({
    type: CONTRACT_LIST_GET_LIST_SUCCESS,
    payload: items
});

export const getContractListError = (error) => ({
    type: CONTRACT_LIST_GET_LIST_ERROR,
    payload: error
});

export const getContractListWithFilter = (column, value) => ({
    type: CONTRACT_LIST_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getContractListWithOrder = (column) => ({
    type: CONTRACT_LIST_GET_LIST_WITH_ORDER,
    payload: column
});

export const getContractListSearch = (keyword) => ({
    type: CONTRACT_LIST_GET_LIST_SEARCH,
    payload: keyword
});

export const addContract = (item) => ({
    type: CONTRACT_LIST_ADD,
    payload: item
});

export const addContractSuccess = (items) => ({
    type: CONTRACT_LIST_ADD_SUCCESS,
    payload: items
});

export const deleteContractsError = (error) => ({
    type: CONTRACT_LIST_DELETE_ERROR,
    payload: error
});

export const deleteContracts = (items) => ({
    type: CONTRACT_LIST_DELETE,
    payload: items
});

export const deleteContractsSuccess = (items) => ({
    type: CONTRACT_LIST_DELETE_SUCCESS,
    payload: items
});

export const addContractError = (error) => ({
    type: CONTRACT_LIST_ADD_ERROR,
    payload: error
});


export const selectedContractsChange = (selectedItems) => ({
    type: CONTRACT_LIST_SELECTED_CHANGE,
    payload: selectedItems
});
