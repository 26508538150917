/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const CHANGE_USER = "CHANGE_USER";
export const GET_USER = "GET_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* CONTRACT LIST APP*/
export const CONTRACT_LIST_GET_LIST = "CONTRACT_LIST_GET_LIST";
export const CONTRACT_LIST_GET_LIST_SUCCESS = "CONTRACT_LIST_GET_LIST_SUCCESS";
export const CONTRACT_LIST_GET_LIST_ERROR = "CONTRACT_LIST_GET_LIST_ERROR";
export const CONTRACT_LIST_GET_LIST_WITH_FILTER = "CONTRACT_LIST_GET_LIST_WITH_FILTER";
export const CONTRACT_LIST_GET_LIST_WITH_ORDER = "CONTRACT_LIST_GET_LIST_WITH_ORDER";
export const CONTRACT_LIST_GET_LIST_SEARCH = "CONTRACT_LIST_GET_LIST_SEARCH";
export const CONTRACT_LIST_ADD = "CONTRACT_LIST_ADD";
export const CONTRACT_LIST_ADD_SUCCESS = "CONTRACT_LIST_ADD_SUCCESS";
export const CONTRACT_LIST_ADD_ERROR = "CONTRACT_LIST_ADD_ERROR";
export const CONTRACT_LIST_DELETE = "CONTRACT_LIST_DELETE";
export const CONTRACT_LIST_DELETE_SUCCESS = "CONTRACT_LIST_DELETE_SUCCESS";
export const CONTRACT_LIST_DELETE_ERROR = "CONTRACT_LIST_DELETE_ERROR";
export const CONTRACT_LIST_SELECTED_CHANGE = "CONTRACT_LIST_SELECTED_CHANGE";

/* CONTRACT DETAIL APP*/
export const CONTRACT_GET_DETAILS = "CONTRACT_GET_DETAILS";
export const CONTRACT_GET_DETAILS_SUCCESS = "CONTRACT_GET_DETAILS_SUCCESS";
export const CONTRACT_GET_DETAILS_ERROR = "CONTRACT_GET_DETAILS_ERROR";
export const CONTRACT_ADD_CLAUSE = "CONTRACT_ADD_CLAUSE";
export const CONTRACT_DELETE_CLAUSE = "CONTRACT_DELETE_CLAUSE";
export const CONTRACT_PREVIEW = "CONTRACT_PREVIEW";
export const CONTRACT_PREVIEW_SUCCESS = "CONTRACT_PREVIEW_SUCCESS";
export const CONTRACT_PREVIEW_ERROR = "CONTRACT_PREVIEW_ERROR";
export const CONTRACT_SAVE = "CONTRACT_SAVE";
export const CONTRACT_SAVE_SUCCESS = "CONTRACT_SAVE_SUCCESS";
export const CONTRACT_SAVE_ERROR = "CONTRACT_SAVE_ERROR";
export const CONTRACT_REVIEW = "CONTRACT_REVIEW";
export const CONTRACT_REVIEW_SUCCESS = "CONTRACT_REVIEW_SUCCESS";
export const CONTRACT_REVIEW_ERROR = "CONTRACT_REVIEW_ERROR";
export const CONTRACT_SIGN = "CONTRACT_SIGN";
export const CONTRACT_SIGN_SUCCESS = "CONTRACT_SIGN_SUCCESS";
export const CONTRACT_SIGN_ERROR = "CONTRACT_SIGN_ERROR";
export const CONTRACT_PUBLISH = "CONTRACT_PUBLISH";
export const CONTRACT_PUBLISH_SUCCESS = "CONTRACT_PUBLISH_SUCCESS";
export const CONTRACT_PUBLISH_ERROR = "CONTRACT_PUBLISH_ERROR";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./contractList/actions";
export * from "./contractDetail/actions";
