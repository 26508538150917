import { all } from 'redux-saga/effects';
import contractListSagas from './contractList/saga';
import contractDetailSagas from './contractDetail/saga';

export default function* rootSaga(getState) {
  yield all([
    contractListSagas(),
    contractDetailSagas()
  ]);
}
