import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import contractListApp from './contractList/reducer';
import contractDetailApp from './contractDetail/reducer';

const reducers = combineReducers({
  menu,
  settings,
  contractListApp,
  contractDetailApp
});

export default reducers;