import {
    CHANGE_LOCALE,
    CHANGE_USER,
    GET_USER
} from '../actions';


export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return (
        {
            type: CHANGE_LOCALE,
            payload: locale
        }
    )
}

export const getUser = () => {
    return (
        {
            type: GET_USER
        }
    )
}

export const changeUser = (user) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
    return (
        {
            type: CHANGE_USER,
            payload: user
        }
    )
}


