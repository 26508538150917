import {
    CONTRACT_GET_DETAILS,
    CONTRACT_GET_DETAILS_SUCCESS,
	CONTRACT_GET_DETAILS_ERROR,
	CONTRACT_ADD_CLAUSE,
	CONTRACT_DELETE_CLAUSE,
	CONTRACT_PREVIEW,
	CONTRACT_SAVE,
	CONTRACT_REVIEW,
	CONTRACT_SIGN,
	CONTRACT_PUBLISH
} from '../actions';

const INIT_STATE = {
	contract: null,
	loading: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
			
		case CONTRACT_GET_DETAILS:
			return { ...state, loading: false, id: action.payload };

		case CONTRACT_GET_DETAILS_SUCCESS:
			return { ...state, loading: true, contract: action.payload.contract, manifest: action.payload.manifest};

		case CONTRACT_GET_DETAILS_ERROR:
			return { ...state, loading: true, error: action.payload };

		case CONTRACT_ADD_CLAUSE:
			return { ...state, loading: false, clauseId: action.payload.clauseId, contract: action.payload.contract };
		
		case CONTRACT_DELETE_CLAUSE:
			return { ...state, loading: false, clauseId: action.payload.clauseId, contract: action.payload.contract };

		case CONTRACT_PREVIEW:
			return { ...state, loading: true, contract: action.payload};

		case CONTRACT_SAVE:
			return { ...state, loading: true, contract: action.payload};

		case CONTRACT_REVIEW:
			return { ...state, loading: true, contract: action.payload};

		case CONTRACT_SIGN:
			return { ...state, loading: true, contract: action.payload};
					
		case CONTRACT_PUBLISH:
			return { ...state, loading: true, contract: action.payload};


		default: return { ...state };
	}
}
